import React from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { ComponentHeader } from "../../../modules";

function VitalSustainabilityMetrics() {
  const isMobile = useMediaQuery({ query: "(max-width:740px)" });
  const { reports } = useSelector((state) => state.analytics);
  return (
    <div className="mt-1 white p-1 box ">
      <ComponentHeader title="Vital Sustainability Metrics" />
      <div className=" box flex flex-wrap  p-1  gap-1 mt-1 ">
        <div
          className={
            isMobile ? "full-width flex  p-1 " : "mh-2 p-1 flex gap-1   p-1 "
          }
        >
          <img src="../images/carbon.png" alt="" />
          <div className="semi-bold ">
            <p className="bold-title">{reports.co2_avoided}</p>
            <p className="gray">Carbon Avoided</p>
          </div>
        </div>
        <div
          className={
            isMobile ? "flex  p-1 full-width " : "mh-2 p-1 flex gap-1  "
          }
        >
          <img src="../images/carbon-footprint.png" alt="" />
          <div className="semi-bold">
            <p className="bold-title">{reports?.carbon_footPrint}</p>
            <p className="gray">Carbon Footprint</p>
          </div>
        </div>

        <div
          className={
            isMobile ? "full-width flex  p-1 " : "mh-2 p-1 flex gap-1  "
          }
        >
          <img src="../images/energy-saving.png" alt="energy-saving" />
          <div className="semi-bold">
            <p className="bold-title">{reports.energy_saved}</p>
            <p className="gray">Energy Savings (KWH)</p>
          </div>
        </div>

        <div
          className={
            isMobile ? "full-width flex  p-1 " : "mh-2 p-1 flex gap-1  "
          }
        >
          <img src="../images/landfills.png" alt="landfills" />
          <div className="semi-bold">
            <p className="bold-title">{reports.landfills_saved}</p>
            <p className="gray">Landfills Saved</p>
          </div>
        </div>

        <div className={isMobile ? "full-width flex  p-1 " : "mh-2 p-1 flex  "}>
          <img src="../images/trees-saving.png" alt="trees-saving" />
          <div className="semi-bold ml-1">
            <p className="bold-title">{reports.trees_saved}</p>
            <p className="gray">Trees Saved</p>
          </div>
        </div>

        <div
          className={
            isMobile ? "full-width flex  p-1 " : "mh-2 p-1 flex ml-1  "
          }
        >
          <img src="../images/water-saved.png" alt="water-saved" />
          <div className="semi-bold">
            <p className="bold-title">{reports.water_saved}</p>
            <p className="gray">Water Saved (kL)</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VitalSustainabilityMetrics;
