import React, { useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image
} from "@react-pdf/renderer";

function PDFReport({
  reportData,
  reportLoading,
  organizationName,
  address,
  endDate,
  startDate
}) {
  const Table = ({ children }) => <View style={styles.table}>{children}</View>;

  const TableCell = ({ children, style }) => (
    <View style={styles.tableCell}>
      <Text
        style={{
          color: "#263238",
          fontSize: 9.5,
          ...style
        }}
      >
        {children}
      </Text>
    </View>
  );

  const TableHeader = ({ children }) => (
    <View style={styles.headerRow}>{children}</View>
  );

  const HeaderText = ({ children, style }) => (
    <View style={styles.tableCell}>
      <Text
        style={{
          fontWeight: 700,
          color: "#7C8DB5",
          fontSize: 9.5,
          ...style
        }}
      >
        {children}
      </Text>
    </View>
  );

  const TableRow = ({ children }) => (
    <View style={styles.tableRow}>{children}</View>
  );

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#fff"
    },

    table: {
      display: "table",
      width: "100%",
      borderRadius: 5,
      padding: 2,
      marginVertical: 7
    },
    tableRow: {
      flexDirection: "row",
      height: 26,
      width: "100%",
      backgroundColor: "#fff",
      borderWidth: 0.8,
      borderColor: "#DADAE7",
      borderRadius: 4,
      gap: 1,
      marginVertical: 2
    },
    tableCell: {
      marginVertical: 5,
      width: "15%",
      marginHorizontal: 1,
      justifyContent: "center",
      alignItems: "center"
    },
    headerRow: {
      backgroundColor: "#EAECF0",
      height: 33,
      width: "100%",
      flexDirection: "row",
      gap: 1,
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      borderBottomWidth: 1,
      borderColor: "#DADAE7",
      marginVertical: 5
    },
    largeTitle: {
      fontSize: 50,
      fontWeight: 1600,
      textAlign: "center",
      color: "#fff"
    },
    firstPageHeader: {
      backgroundColor: "#6f2da8",
      height: "75%",
      width: "100%",
      justifyContent: "center"
    },

    titleText: {
      fontSize: 20,
      fontWeight: 800
    },

    normalText: {
      fontSize: 15,
      fontWeight: 400
    },
    firstPageBottom: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 20
    },
    pdfBody: {
      padding: 10,
      backgroundColor: "#fff"
    },
    analyticsTitle: {
      fontSize: 15,
      fontWeight: 800
    },
    analyticsItem: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 2,
      borderRadius: 5,
      borderWidth: 1,
      borderColor: "#DADAE7",
      width: "100%",
      paddingHorizontal: 25,
      paddingVertical: 10
    },
    analyticsValue: {
      color: "#6f2da8",
      fontWeight: 800,
      fontSize: 20
    }
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.firstPageHeader}>
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Image
              src={"../images/wasteknot_logo.png"}
              style={{
                width: 100,
                height: 100
              }}
            />
            <Text style={styles.largeTitle}>Wasteknot Report</Text>
            <Text
              style={{ ...styles.normalText, color: "#fff", marginTop: 10 }}
            >
              {new Date(startDate).toDateString()}-{" "}
              {new Date(endDate).toDateString()}
            </Text>
          </View>
        </View>

        <View style={styles.firstPageBottom}>
          <View></View>
          <View style={{ gap: 7 }}>
            <Text style={styles.titleText}>Specially designed for</Text>
            <Text style={styles.titleText}>{organizationName}</Text>
            <Text style={styles.normalText}>
              {address.nickname}: {address.address}
            </Text>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.pdfBody}>
        <View style={{ marginVertical: 20 }}>
          <Text style={{ ...styles.titleText, fontSize: 15, marginBottom: 7 }}>
            Report Analytics
          </Text>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
              justifyContent: "space-between"
            }}
          >
            <View style={{ ...styles.analyticsItem }}>
              <Text style={{ ...styles.analyticsTitle }}>Total Pickups</Text>
              <Text style={{ ...styles.analyticsValue }}>
                {reportData.totalPickups}
              </Text>
            </View>

            <View style={{ ...styles.analyticsItem }}>
              <Text style={{ ...styles.analyticsTitle }}>Total Quantity</Text>
              <Text style={{ ...styles.analyticsValue }}>
                {reportData.totalQuantity}
              </Text>
            </View>
          </View>
        </View>
        <Text style={{ ...styles.titleText, fontSize: 15 }}>
          Pickups History:
        </Text>
        <Table>
          <TableHeader>
            <HeaderText style={{ width: 10 }}>#</HeaderText>
            <HeaderText>Pickup Date</HeaderText>
            <HeaderText>Quantity</HeaderText>
          </TableHeader>

          {reportData.pickups?.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={{ width: 10 }}>{index + 1}</TableCell>
              <TableCell>{new Date(row.date).toDateString()}</TableCell>
              <TableCell>{row.quantity} MT</TableCell>
            </TableRow>
          ))}
        </Table>
      </Page>
    </Document>
  );
}

export default PDFReport;
