import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ComponentHeader } from "../modules";
import { getPickupsHistory } from "../redux/action/pickups";
import { Loading } from "../modules";
import { Pagination } from "react-materialize";

const PickupsHistory = () => {
  const navigate = useNavigate();
  const { history, historyLoading } = useSelector((state) => state.pickups);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPickupsHistory());
  }, [dispatch]);

  return (
    <section className="p-1">
      <ComponentHeader title="Pickups History" />
      <div className="box  full-width white ">
        <table className="striped  full-width ">
          <thead>
            <tr>
              <th className="grey-text">#</th>
              <th className="purple-text">Pickup Date </th>
              <th className="green-text">Processor</th>
              <th className="pink-text">Scrap</th>
              <th className="blue-text">Price</th>
              <th className="black-text">Quantity</th>
              <th className="brown-text">Status</th>
            </tr>
          </thead>

          <tbody className="responsive-table">
            {history.length === 0 ? (
              <div className="flex column align-center full-width  mv-2 p-2">
                <img
                  src="../images/pickup.png"
                  style={{ width: "130px", height: "100px" }}
                  alt="pickup"
                />
                <p className="grey-text mt-1 ">
                  Currently you don’t have any pickups.
                </p>
              </div>
            ) : (
              history.map((item, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => navigate("/pickup/" + item._id)}
                    className="tableHover"
                  >
                    <td>{index + 1}</td>
                    <td>{new Date(item.date).toDateString()}</td>
                    <td>{item.processor?.name}</td>
                    <td>
                      {item.scrap?.name}-{item.scrap?.variant}
                    </td>
                    <td>₹ {item.price}/kg</td>
                    {item.completed ? (
                      <td> {item.quantity?.toFixed(2)} MT</td>
                    ) : (
                      <td> YET TO BE CONFIRMED</td>
                    )}

                    <td>
                      <span
                        className={`${(() => {
                          let colors = {
                            pending: "orange-text",
                            completed: "green-text",
                            assigned: "blue-text",
                            rejected: "red-text",
                            cancelled: "red-text",
                          };
                          return colors[item.status];
                        })()}`}
                      >
                        {item.status}
                      </span>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default PickupsHistory;
