import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "react-materialize";
import { BeatLoader } from "react-spinners";
import { useMediaQuery } from "react-responsive";
import M from "materialize-css";
import addSeconds from "date-fns/addSeconds";
import { isToday } from "../../../helpers/helper";
import {
  raiseDirectPickup,
  raisePickup,
  raiseThirdPartyPickup
} from "../../../redux/action/pickups";
import { notifyError } from "../../../helpers/helper";

function RaisePickups({
  selectedNetwork,
  scrapContract,
  scrap,
  setScrap,
  pickupLocation,
  setPickupLocation
}) {
  const [numberOfVehicles, setNumberOfVehicles] = useState(1);
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const { organization } = useSelector((state) => state.organization);
  const { account } = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const [date, setDate] = useState("");
  const { loading } = useSelector((state) => state.pickups);
  const [price, setPrice] = useState("");
  const currentDate = new Date();

  const handleDateChange = (value) => {
    isToday(value)
      ? setDate(addSeconds(new Date(new Date().getTime() + 10 * 60000), 3600))
      : setDate(addSeconds(value, 3600));
  };
  const { locations } = organization;

  function reset() {
    setPickupLocation("");
    setDate("");
    setScrap("");
    setPrice("");
    setNumberOfVehicles(1);
  }

  function raise() {
    let selected = locations?.find((loc) => loc._id === pickupLocation);
    if (!scrapContract)
      return M.toast({ html: "No contract found for this scrap" });

    const data = {
      date,
      pickupLocation: selected,
      scrap,
      numberOfVehicles,
      price: "0"
    };

    if (scrapContract?.type === "third-party")
      return dispatch(raiseThirdPartyPickup(data, scrapContract?._id, reset));

    dispatch(raisePickup(data, scrapContract?._id, reset));
  }

  function raiseDirect() {
    if (!price) return notifyError("Please enter a price");
    let selected = locations?.find((loc) => loc._id === pickupLocation);
    const data = {
      date,
      pickupLocation: selected,
      scrap: scrap,
      numberOfVehicles,
      price
    };

    dispatch(raiseDirectPickup(data, selectedNetwork.organization?._id, reset));
  }

  function raiseNewPickup() {
    if (scrapContract) {
      raise();
    } else {
      raiseDirect();
    }
  }

  return (
    <div className=" mt-1 raise-pickups ">
      <div className="raise-pickups-section">
        <div className="flex space-between">
          <p className="bold">Raise A Pickup Request</p>

          {selectedNetwork && (
            <p>
              To:{" "}
              <span className="cercle-purple-text">
                {selectedNetwork?.organization?.name}
              </span>
            </p>
          )}
        </div>

        <div className={!isTablet ? "flex s_b " : "flex  column "}>
          <div className="mt-1 flex full-width flex-wrap gap-1">
            <div style={{ width: isTablet ? "100%" : "30%" }} className="mr-1">
              <select
                name="place"
                className="browser-default select-item full-width input-style"
                onChange={(e) => {
                  setPickupLocation(e.target.value);
                }}
                value={pickupLocation}
              >
                <option value="">Select a location</option>
                {locations?.map((item, index) => {
                  return (
                    <option key={index} value={item._id}>
                      {item.nickname} - {item.address}
                    </option>
                  );
                })}
              </select>
            </div>

            <div
              style={{ width: isTablet ? "100%" : "30%" }}
              className=" mr-1 "
            >
              {
                <select
                  className="browser-default select-item full-width input-style"
                  onChange={(e) => {
                    setScrap(e.target.value);
                  }}
                  value={scrap}
                >
                  <option value="">Select Scrap</option>
                  {organization.scraps?.map((scrap, index) => (
                    <option key={index} value={scrap._id}>
                      {scrap.name}-{scrap.variant}
                    </option>
                  ))}
                </select>
              }
            </div>
            <div style={{ width: isTablet ? "100%" : "30%" }}>
              <div
                className="flex  input-style align-center  custom-datepicker"
                style={{ borderColor: "#6f2da8" }}
              >
                <span className="material-symbols-outlined  grey-text">
                  calendar_month
                </span>
                <DatePicker
                  placeholder="Choose A Date"
                  className="browser-default input-date"
                  options={{
                    minDate: new Date(new Date().getTime() + 10 * 60000)
                  }}
                  value={date ? date.toDateString() : ""}
                  onChange={(e) => {
                    handleDateChange(e);
                    // setDate(addSeconds(e, 3600));
                    document.body.style.overflow = "auto";
                  }}
                />
              </div>
            </div>

            {!scrapContract && (
              <div style={{ width: isTablet ? "100%" : "30%" }}>
                <div
                  className="flex  input-style align-center  custom-datepicker"
                  style={{ borderColor: "#6f2da8" }}
                >
                  <span
                    className="material-symbols-outlined  grey-text"
                    style={{ marginRight: "5px" }}
                  >
                    money
                  </span>
                  <input
                    type="number"
                    min={0}
                    placeholder="Enter Price per Kg"
                    className="browser-default input-date"
                    value={price}
                    onChange={(e) => {
                      setPrice(e.target.value);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          className={
            !isTablet
              ? "flex mt-2 align-end space-between "
              : " flex gap-1 align-center column mt-2 "
          }
        >
          <div className={isTablet && "flex column align-center"}>
            <p className="semi-bold">Number Of Vehicles</p>
            <div className="flex align-center mt-1 gap-1  ">
              <button
                className="countrol-btn"
                onClick={() =>
                  numberOfVehicles - 1 >= 1
                    ? setNumberOfVehicles(numberOfVehicles - 1)
                    : null
                }
              >
                <span className="material-symbols-outlined icon " role="button">
                  remove
                </span>
              </button>
              <p
                className="bold input ml-1 mr-1"
                style={{ justifyContent: "center" }}
              >
                {numberOfVehicles}
              </p>
              <button
                className="countrol-btn"
                onClick={() => setNumberOfVehicles(numberOfVehicles + 1)}
              >
                <span className="material-symbols-outlined icon" role="button">
                  add
                </span>
              </button>
            </div>
          </div>

          <div>
            <button
              className="btn"
              onClick={raiseNewPickup}
              disabled={
                !scrap ||
                !date ||
                !pickupLocation ||
                !selectedNetwork ||
                !account.permissions?.includes("raise-pickup")
              }
            >
              {loading ? <BeatLoader color="#fff" size={10} /> : "Raise Pickup"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RaisePickups;
