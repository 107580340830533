import { IWM } from "../config/urlConfig";

export const getHistoryWithANetwork = (id) => (dispatch) => {
  dispatch({ type: "GETTING_PICKUP_HISTORY_WITH_A_NETWORK" });
  IWM.get("/pickups/read/organization/history/" + id)
    .then((res) => {
      dispatch({
        type: "GOT_PICKUP_HISTORY_WITH_A_NETWORK",
        payload: res.data
      });
    })
    .catch((ex) => {
      dispatch({ type: "CANNOT_GET_PICKUP_HISTORY_WITH_A_NETWORK" });
    });
};

export const getAnalyticsOverview = () => (dispatch) => {
  dispatch({ type: "GETTING_OVERVIEW" });
  IWM.get("/analytics/read/overview")
    .then((res) => {
      dispatch({ type: "GOT_OVERVIEW", payload: res.data });
    })
    .catch((ex) => {
      dispatch({ type: "CANNOT_GET_OVERVIEW" });
    });
};

export const getMonthlyQuantityOverTime = (months) => (dispatch) => {
  dispatch({ type: "GETTING_MONTHLY_QUANTITY_OVER_TIME" });

  IWM.get(
    "/analytics/read/monthly-quantity-over-time" +
      (months ? "?months=" + months : "")
  )
    .then((res) => {
      dispatch({
        type: "GOT_MONTHLY_QUANTITY_OVER_TIME",
        payload: res.data
      });
    })
    .catch((ex) => {
      dispatch({ type: "CANNOT_GET_MONTHLY_QUANTITY_OVER_TIME" });
    });
};

export const getQuantityOverTime = (start, end) => (dispatch) => {
  dispatch({ type: "GETTING_QUANTITY_OVER_TIME" });

  IWM.get(
    "/analytics/read/quantity-over-time" +
      (start ? "?start=" + start : "") +
      (end ? "&end=" + end : "")
  )
    .then((res) => {
      dispatch({
        type: "GOT_QUANTITY_OVER_TIME",
        payload: res.data
      });
    })
    .catch((ex) => {
      dispatch({ type: "CANNOT_GET_QUANTITY_OVER_TIME" });
    });
};

export const getReports = () => (dispatch) => {
  dispatch({ type: "GETTING_REPORTS" });

  IWM.get("/analytics/read/reports")
    .then((res) => {
      dispatch({
        type: "GOT_REPORTS",
        payload: res.data
      });
    })
    .catch((ex) => {
      dispatch({ type: "CANNOT_GET_REPORTS" });
    });
};

export const getReportDataAction = (start, end) => (dispatch) => {
  dispatch({ type: "GETTING_REPORT_DATA" });
  IWM.get(`/analytics/read/pickups-report?start=${start}&end=${end}`)
    .then((res) => {
      dispatch({ type: "GOT_REPORT_DATA", payload: res.data });
    })
    .catch((ex) => {
      console.log(ex);
      dispatch({ type: "CANNOT_GET_REPORT_DATA" });
    });
};
