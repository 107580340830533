import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { useDispatch, useSelector } from "react-redux";
import { ComponentHeader, DashboardCard } from "../../modules";
import { getReports } from "../../redux/action/analytics";
import QuantityOverTime from "./components/QuantityOverTime";
import ReportsPickupHistory from "./components/ReportsPickupHistory";
import VitalSustainabilityMetrics from "./components/VitalSustainabilityMetrics";
import PDFReport from "./components/PDFReport";
import {
  getReportDataAction,
  getQuantityOverTime
} from "../../redux/action/analytics";
import { subMonths } from "date-fns";

function Reports() {
  const dispatch = useDispatch();
  const { reports, reportData, reportLoading } = useSelector(
    (state) => state.analytics
  );
  const { organization } = useSelector((state) => state.organization);
  const { account } = useSelector((state) => state.account);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    dispatch(getReports(startDate, endDate));
    dispatch(getReportDataAction(startDate, endDate));
  }, [dispatch]);

  useEffect(() => {
    const start = subMonths(new Date(), 1);
    setStartDate(start);
  }, []);

  useEffect(() => {
    if (!startDate || !endDate) return;
    dispatch(getQuantityOverTime(startDate, endDate));
    dispatch(getReportDataAction(startDate, endDate));
  }, [startDate, endDate, dispatch]);

  return (
    <div className="p-1">
      <div className="flex align-center space-between ">
        <ComponentHeader title="Overall Reports & Analytics" />

        <div className="flex align-center gap-1 ">
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            className="browser-default input-style "
          />
          <PDFDownloadLink
            document={
              <PDFReport
                reportData={reportData}
                reportLoading={reportLoading}
                organizationName={organization.name}
                address={organization.locations[0]}
                startDate={startDate}
                endDate={endDate}
              />
            }
            fileName="Wasteknot Report"
          >
            {({ loading, error }) =>
            reportLoading ? (
                <button className="btn btn-primary btn-sm " disabled >
                Loading...
              </button>
              ) : (
                <button className="btn btn-primary btn-sm ">
                  Download Report
                </button>
              )
            }
          </PDFDownloadLink>
        </div>
      </div>
      <div className="mt-2">
        <QuantityOverTime startDate={startDate} endDate={endDate} />

      </div>

      <div className="mt-2">
        <ReportsPickupHistory />
      </div>

      {account.permissions.includes("read-analytics") && (
        <div>
          <ComponentHeader title="Your Credits Report" />
          <div className="flex flex-wrap gap-1 ">
            <DashboardCard
              title="CERCLEX CREDITS"
              amount={reports?.cercle_credits}
              desc="T&C's Apply"
              img="../images/cerclex.png"
            />

            <DashboardCard
              title="PLASTIC CREDITS"
              amount={reports?.plastic_credits ? reports?.plastic_credits : 0}
              desc="T&C's Apply"
              img="../images/plastic.png"
            />

            <DashboardCard
              title="CARBON CREDITS"
              desc="T&C's Apply"
              amount={reports?.carbon_credits ? reports?.carbon_credits : 0}
              img="../images/carbon.png"
            />
          </div>
        </div>
      )}

      {account.permissions.includes("read-analytics") && (
        <VitalSustainabilityMetrics reports={reports} />
      )}
    </div>
  );
}

export default Reports;
